<template>
    <div class="Setting">
        <div class="myView" style="padding-top:15px;">

            <van-cell-group>

                <div @touchend="$router.push('/mail/MailAccount')">
                    <!-- 邮箱设置 -->
                    <van-cell :value="$t('mx_mobile.Client.1585639992478')" is-link />
                </div>

            </van-cell-group>

        </div>
    </div>
</template>

<script>
import titleMixin from '../../../mixin/title'
export default {
    name: 'Setting',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.App.1584683241860') }, // '我的设置'
    data() {
        return {
            pageUrl: ''
        }
    },
    created() {
        this.pageUrl = this.$route.path
        // this.setMenu()
    },
    methods: {
        // setMenu() {
            // .setRight()
        // }
    },
    watch: {
        // $route(to, from) {
        //     if (to.path === this.pageUrl) {
        //         this.setMenu()
        //     }
        // }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
</style>
